* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Arial', Arial, sans-serif;

}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}

a {
  color: inherit;
  text-decoration: none;
}
li{
  list-style: none;
}
.hero {
  background-image: url("../assets/images/wm-1.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  padding-bottom: 80px ;
}

.hero::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #B68C63;
  opacity: 0.4;
  z-index: 0;
}

.header {
  padding: 30px 0;
  position: relative;
  z-index: 1;
  margin-bottom: 50px;

}

.header__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #41210a;
  font-size: 13px;
  line-height: 1.55;
  font-weight: 600;
  text-transform: uppercase;
  border-width: 1px;
}

.header__logo {
  width: 250px;
  height: 150px;
}

.header__logo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.header__link {
  font-size: 13px;
  line-height: 1.55;
  font-weight: 600;
  text-transform: uppercase;
  border-width: 1px;
  border-radius: 30px;
  margin-left: 15px;
}
.header__link:hover {
color: #fec802;
}

.header__num {
  padding: 5px;
  margin-bottom: 5px;
  font-size: 20px;
  display: block;
  border-width: 2px;
  border-radius: 25px;
  opacity: 0.7;
  background-color: #fec802;
  border-color: #41210a;
  border-style: solid;
  box-shadow: 4px 4px 10px 2px rgba(65, 33, 10, 0.2);

}

.header__socials {
  display: flex;

}

.header__social {
  width: 55px;
  height: 55px;
  margin-left: 20px;
  cursor: pointer;
}

.header__social img {
  width: 100%;
  height: 100%;
}



/* hero */
.hero__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;
}

.content__title {
  font-size: 40px;
  line-height: 1.3;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 52px;
  position: relative;
  z-index: 1;
  color: #41210a;
  text-align: center;

}

.content__title::before {
  content: "";
  width: 101%;
  height: 50%;
  position: absolute;
  background-color: #F6C73B;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0.9;
  bottom: -5px;
  z-index: -1;
}

.hero__subtitle {
  font-size: 35px;
  line-height: 1.3;
  font-weight: 500;
  text-transform: uppercase;
}

.hero__categories-top {
  display: flex;
  justify-content: space-between;
  max-width: 90%;
  margin: 0 auto;
  margin-bottom: 50px;
}

.hero__categories-bottom {
  display: flex;
  justify-content: space-between;
  max-width: 50%;
  margin: 0 auto;
}


.hero__category {
  border-width: 2px;
  border-radius: 25px;
  opacity: 0.7;
  background-color: #fec802;
  border-color: #41210a;
  border-style: solid;
  box-shadow: 4px 4px 10px 2px rgba(65, 33, 10, 0.2);
  width: 28%;
  height: 100px;
  padding: 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 25px;
  line-height: 1.3;
  font-weight: 500;
  color: #41210a;
}
.hero__none{
  display: none;
}

.hero__category-bottom {
  width: 44%;
}

.section__contact {
  display: flex;
  justify-content: center;
  margin-top: 100px;

}

.custom-btn {
  text-transform: uppercase;
  font-size: 20px;
  width: 250px;
  height: 70px;
  color: #fff;
  border-radius: 5px;
  padding: 10px 25px;
  font-family: 'Lato', sans-serif;
  font-weight: 500;
  background: transparent;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, .5),
      7px 7px 20px 0px rgba(0, 0, 0, .1),
      4px 4px 5px 0px rgba(0, 0, 0, .1);
  outline: none;
}

.btn-5 {

  line-height: 42px;
  padding: 0;
  border: none;
  background: rgb(255, 27, 0);
  background: linear-gradient(0deg, rgba(255, 27, 0, 1) 0%, rgba(251, 75, 2, 1) 100%);
}

.btn-5:hover {
  color: #f0094a;
  background: transparent;
  box-shadow: none;
}

.btn-5:before,
.btn-5:after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  height: 2px;
  width: 0;
  background: #f0094a;
  box-shadow:
      -1px -1px 5px 0px #fff,
      7px 7px 20px 0px #0003,
      4px 4px 5px 0px #0002;
  transition: 400ms ease all;
}

.btn-5:after {
  right: inherit;
  top: inherit;
  left: 0;
  bottom: 0;
}

.btn-5:hover:before,
.btn-5:hover:after {
  width: 100%;
  transition: 800ms ease all;
}





/* hero */



/* price */



.price {
  padding: 100px 0;
  background-color: #d8b484;
}

.price__title {
  max-width: 290px;
  margin: 0 auto;
  margin-bottom: 70px;
}

.price__head {
  border-bottom: 1px solid #90613f;
}

.price__subtitle {
  color: #90613f;
  text-transform: uppercase;
}

.price__content {
  padding: 20px;
  display: grid;
  grid-template-columns: 6fr 1fr 1fr;
}

.price__content-dark {
  background-color: #daad77;
}

/* price */



/* about */
.about {
  padding: 220px 0 80px;
  background-image: url("../assets/images/man.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.about__wrapper {
  max-width: 780px;
  margin: 0 auto;
}

.about__title {
  margin: 0 auto;
  margin-bottom: 240px;

}

.about__desc {
  color: #41210a;
  font-size: 20px;
  line-height: 1.55;
  font-weight: 600;
  margin-bottom: 50px;
  display: flex;
  align-items: center;
}

.about__desc img {
  width: 40px;
  height: 40px;
  margin-right: 20px;
}

/* about */








/* faq */
.faq{
  padding: 80px 0;
}
.faq__wrapper {

  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 50px;
}
.faq__title{
  max-width: 700px;
  margin: 0 auto;
  margin-bottom: 100px;
}
.faq__desc{
  border-bottom: 1px solid #FEC802;
  padding: 20px 0;

}







.accordion {
  .accordion-item {
    border-bottom: 1px solid #F6C73B;
    button[aria-expanded='true'] {
      border-bottom: 1px solid #fec802;
    }
  }
  .faq__button {
    position: relative;
    display: block;
    text-align: left;
    width: 100%;
    padding: 1em 0;
    color: #000;
    font-size: 1.15rem;
    font-weight: 400;
    border: none;
    background: none;
    outline: none;
    &:hover, &:focus {
      cursor: pointer;
      color: #F6C73B;
      &::after {
        cursor: pointer;
        color: #FEC802;
        border: 1px solid #fec802;
      }
    }
    .accordion-title {
      padding: 1em 1.5em 1em 0;
    }
    .icon {
      display: inline-block;
      position: absolute;
      top: 18px;
      right: 0;
      width: 22px;
      height: 22px;
      border: 1px solid;
      border-radius: 22px;
      &::before {
        display: block;
        position: absolute;
        content: '';
        top: 9px;
        left: 5px;
        width: 10px;
        height: 2px;
        background: currentColor;
      }
      &::after {
        display: block;
        position: absolute;
        content: '';
        top: 5px;
        left: 9px;
        width: 2px;
        height: 10px;
        background: currentColor;
      }
    }
  }
  .faq__button[aria-expanded='true'] {
    color: #F6C73B;
    .icon {
      &::after {
        width: 0;
      }
    }
    + .accordion-content {
      opacity: 1;
      max-height: 9em;
      transition: all 200ms linear;
      will-change: opacity, max-height;
    }
  }
  .accordion-content {
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    transition: opacity 200ms linear, max-height 200ms linear;
    will-change: opacity, max-height;
    p {
      font-size: 1rem;
      font-weight: 300;
      margin: 2em 0;
    }
  }
}


/* testimonials */




/* fix */
.fix{
  padding: 80px 0;
  background-color: rgb(240, 230, 221);

}
.fix__title{
  max-width: 450px;
  margin: 0 auto;
  margin-bottom: 100px;
}


.fix__wrapper{
  display: grid;
  row-gap: 50px;
  grid-template-columns: 1fr 1fr;
}
.fix__swipper{
  max-width: 600px;
  margin: 0 auto;
 display: none;
 
}
.fix__card{
  display: flex;


}
.fix__subtitle{
  margin-bottom: 20px;
  font-size: 16px;
}
.fix__desc{
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 20px;
}
.fix__content {
  max-width: 400px;
}
.fix__img{
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin-right: 20px;
  overflow: hidden;
}
.fix__img img{
  width: 100%;
  height: 100%;
  object-fit: cover;
 
}

.fix__tick{
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
.fix__tick img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}




/* fix */






/* models */

.models{
  /* background-color: rgb(240, 230, 221); */
  padding: 80px 0;
}
.models__title{
  max-width: 700px;
  margin: 0 auto;
  margin-bottom: 100px;
}
.models__wrapper{
  display: grid;
  grid-template-columns: repeat(5,1fr);
  gap: 100px;
}
.models__card{
  border: 2px solid #F6C73B;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center; 
}


.models__card:hover{
transform: scale(1.2);
transition: 0.6s;
}
/* models */








/* footer */

.footer{
  background-color: #fec802;
  padding: 30px 0;
}
.footer__wrapper{
  display: grid;
  grid-template-columns: 0.6fr 1fr 1fr 1fr;
  column-gap: 100px;
  align-items: center;
}
.footer__title{
  margin-bottom: 30px;
  color: #41210a;
}
.footer__contact{
  margin-bottom: 20px;
  font-size: 15px;
  color: #41210a;
}
/* footer */













